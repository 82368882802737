<template>
	<div>
		<div class="card">
			<div class="card-body" v-if="!isLoading">
				<form class="row g-3"  @submit.prevent="onSubmit" novalidate>
					<div class="col-4">
						<label class="form-label">Subject name</label>
						<select class="form-select" v-model="vv.subject_id.$model">
							<option :value="initialValue">Select Course</option>
							<option v-for="(item, i) in subjectList" :key="i" :value="item.subject_id">{{ item.subject_title }}</option>
						</select>
						<span class="error">{{ vv?.subject_id?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-4">
						<label class="form-label">Question Year</label>
						<select class="form-select" v-model="vv.year_id.$model">
							<option value="0">All Years</option>
							<option v-for="(item, i) in yearList" :key="i" :value="item.year_id">{{ item.year_name }}</option>
						</select>
					</div>
					<div class="col-4">
						<label class="form-label">Select Question File to Upload</label>
						<label class="custom-file-upload">
							<input type="file" @change="handleFileChange" id="file-content-input" accept="application/json"/>
							Select The Question File
						</label><br>
						<b class="text-success" v-if="fform.json_data.length > 0">File Selected: {{ file_name }}</b>
						<span class="error">{{ vv?.json_data?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-12">
						<button class="btn btn-primary" :disabled="isProcessing">
							<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-if="isProcessing" style="padding-left: 15px !important;">Uploading</span>
							<span v-if="!isProcessing">Upload Questions</span>
						</button>
					</div>
				</form>
			</div>
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { jambFileMgt } from '@/services/jambFileMgt.service'
export default {
	name: 'UploadQuestionFiles',
	props: {
		yearList: [Array, Object],
		subjectList: [Array, Object]
	},
	setup() {
		const fform = reactive({
			subject_id: null,
			year_id: 0,
			json_data: []
		})
		const rules = computed(() => (
			{
				subject_id: { required },
				year_id: { required },
				json_data: { required }
			}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data () {
		return {
			isLoading: false,
			loadingMessage: 'Loading Questions',
			isProcessing: false,
			initialValue: null,
			file_name: ''
		}
	},
	methods: {
		async handleFileChange () {
			const elem = document.getElementById('file-content-input')
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const reader = new FileReader()
				reader.onload = (e) => {
					try{
						var result = JSON.parse(e.target.result)
						if(result.length === 0){
							this.$swal({
								text: `The Json file you selected is empty`,
								icon: 'error'
							})
							return
						}
						this.file_name = elem.files[0].name
						console.log(result.length)
						this.fform.json_data = result
					}catch{
						this.$swal({
							text: `The selected file is not a valid json file or it is an empty file`,
							icon: 'error'
						})
					}
				}
				reader.readAsText(elem.files[0])
			}
		},
		async onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isProcessing = true
			const postData = {
				subject_id: this.fform.subject_id,
				year_id: this.fform.year_id,
				questions: this.fform.json_data
			}
			jambFileMgt.uploadQuestion(postData)
				.then((response) => {
					this.isProcessing = false
					if(parseInt(response.data.message_code) === 200){
						this.$swal('Past question Upload successsfull')
						this.fform.subject_id = null
						this.fform.year_id = 0
						this.fform.json_data = []
						//this.$emit('closeForm')
					}else{
						this.$swal({
							text: response.data.message,
							icon: 'error'
						})
					}
				})
				.catch((error) => {
					this.isProcessing = false
					console.log(error.message)
					this.$swal({
						text: 'Error Connecting To Server',
						icon: 'error'
					})
				})
			//console.log(postData)
		}
	}
};
</script>

<style>
label{
  font-weight: 550;
  font-size: 14px;
  margin-bottom: 5px;
}
input[type="file"] {
  display: none;
}
.custom-file-upload{
  margin-top: 0px;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #09568d;
  height: auto;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 400 !important;
}
</style>
<template>
	<div style="border: 1px solid #09568d;">
		<form v-if="!isLoading" @submit.prevent="onSubmit" novalidate>
			<nav class="navbar sticky-top navbar-light bg-dark">
				<div class="container-fluid">
					<span class="navbar-brand mb-0 h6 text-light" style="">{{ vv.question_content.$model.length }} question(s) in total</span>
					<div class="">
						<button type="submit" class="btn btn-primary btn-sm" :disabled="isProcessing">
							<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-if="isProcessing" style="padding-left: 15px !important;">Updating......</span>
							<span v-if="!isProcessing">Update Question</span>
						</button>
					</div>
				</div>
			</nav>
			<div class="frame bg-white" style="padding: 2rem 1rem;">
				<div class="card">
					<div class="card-body">
						<div class="row g-3">
							<div class="col-6">
								<label class="form-label">Subject name</label>
								<select class="form-select" v-model="vv.subject_id.$model">
									<option :value="initialValue">Select subject</option>
									<option v-for="(item, i) in subjectList" :key="i" :value="item.subject_id">{{ item.subject_title }}</option>
								</select>
								<span class="error">{{ vv?.subject_id?.$errors[0]?.$message }}</span>
							</div>
							<div class="col-6">
								<label class="form-label">Question Year</label>
								<select class="form-select" v-model="vv.year_id.$model">
									<option :value="initialValue">All Years</option>
									<option v-for="(item, i) in yearList" :key="i" :value="item.year_id">{{ item.year_name }}</option>
								</select>
								<span class="error">{{ vv?.year_id?.$errors[0]?.$message }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-2 card" style="margin-top: 20px !important;" v-for="(item, i) in vv.question_content.$model" :key="i">
					<div class="card-header">
						Question {{ i + 1 }}
					</div>
					<div class="card-body">
						<div class="row g-3">
							<div class="col-12 question">
								<label for="">Question Instruction</label>
								<input type="text" class="form-control" v-model="item.instruction">
								<span class="error">{{ item?.instruction?.$errors?.$message }}</span>
							</div>
							<div class="col-12 question">
								<label for="">Question</label>
								<ckeditor :editor="editor" v-model="item.question" :config="editorConfig"></ckeditor>
								<span class="error">{{ item?.question?.$errors?.$message }}</span>
							</div>
							<div class="col-6 options">
								<label for="">Option A</label>
								<ckeditor :editor="editor" v-model="item.option_a" :config="optionConfig"></ckeditor>
								<span class="error">{{ item?.option_a?.$errors?.$message }}</span>
							</div>
							<div class="col-6 options">
								<label for="">Option B</label>
								<ckeditor :editor="editor" v-model="item.option_b" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options">
								<label for="">Option C</label>
								<ckeditor :editor="editor" v-model="item.option_c" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options">
								<label for="">Option D</label>
								<ckeditor :editor="editor" v-model="item.option_d" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options">
								<label for="">Option E</label>
								<ckeditor :editor="editor" v-model="item.option_e" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6">
								<label for="">Correct Answer</label>
								<select class="form-select" v-model="item.correct_ans">
									<option value="null">Select Correct Answer</option>
									<option value="1"> Option A</option>
									<option value="2"> Option B</option>
									<option value="3"> Option C</option>
									<option value="4"> Option D</option>
									<option value="5"> Option E</option>
								</select>
							</div>
							<div class="col-6 options">
								<label for="">Correction </label>
								<ckeditor :editor="editor" v-model="item.correction" :config="correctionConfig"></ckeditor>
							</div>
							<div class="col-6">
								<div>
									<label>Question Image</label>
								</div>
								<div class="d-flex justify-content-start">
									<div>
										<img class="image" id="previewImage" :src="setImage(item.question_image)" alt="">
									</div>
									<div class="pt-3">
										<label class="custom-file-upload">
											<input type="file" @change="handleFileChange" :data-id="i" id="question-image-input" accept="image/*"/>
											Add Question Related Image
										</label><br>
										<button type="button" class="btn btn-danger btn-sm mt-3" v-if="item.question_image !== null" @click="deleteImage(1, item.question_image)">Delete</button>
									</div>
								</div>
							</div>
							<div class="col-6 options">
								<div>
									<label>Correction Image</label>
								</div>
								<div class="d-flex justify-content-start">
									<div>
										<img class="image" id="previewImage" :src="setImage(item.correction_image)" alt="">
									</div>
									<div class="pt-3">
										<label class="custom-file-upload">
											<input type="file" @change="handleFileChangeCorrection" :data-id="i" id="correction-image-input" accept="image/*"/>
												Add Correction Image
										</label><br>
										<button type="button" class="btn btn-danger btn-sm mt-3" v-if="item.correction_image !== null" @click="deleteImage(2, item.correction_image)">Delete</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<div class="card" v-if="isLoading && !showErrorPageAlert">
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
		<div class="alert alert-danger" v-if="isLoading && showErrorPageAlert" style="margin-bottom: 0px !important;">
			<h4 style="font-size: 17px !important; font-weight: bold;">{{ errorMessage }}</h4>
			<button class="btn btn-danger" @click="loadQuestion" style="margin-top: 7px;">Reload Content</button>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Mathematics from 'ckeditor5-math/src/math';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
//import { projectService } from '@/services/project.service'
import { jambFileMgt } from '@/services/jambFileMgt.service'

export default {
	name: 'CreatePastQuestion',
	props: {
		questionId: Number,
		yearList: [Array, Object],
		subjectList: [Array, Object],
		subjectId: Number
	},
	setup(props) {
		const fform = reactive({
			year_id: null,
			subject_id: props.subjectId,
			question_content: [
				{
					id: null,
					instruction: '',
					question: '',
					option_a: '',
					option_b: '',
					option_c: '',
					option_d: '',
					option_e: '',
					correct_ans: null,
					question_image: '',
					correction: '',
					correction_image: ''
				}
			]
		})
		const rules = computed(() => (
			{
				year_id: { required },
				subject_id: { required },
				question_content: {}
			}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			folder_path: '',
			isLoading: true,
			isProcessing: false,
			showErrorPageAlert: false,
			errorMessage: '',
			loadingMessage: 'Loading Past Question',
			topicList: [],
			initialValue: null,
			question: [],
			questionImageList: [],
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				UnderlinePlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Alignment,
				Mathematics,
				],
				toolbar: {
					items: [
					'bold',
					'underline',
					'italic',
					'undo',
					'redo',
					'alignment',
					'math'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			optionConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				UnderlinePlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Mathematics,
				],
				toolbar: {
					items: [
					'bold',
					'underline',
					'italic',
					'undo',
					'redo',
					'math'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 100,
				allowedContent: true
			},
			correctionConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Mathematics,
				Alignment,
				Base64UploadAdapter,
				Image,
				ImageCaption,
				ImageStyle,
				ImageToolbar,
				ImageUpload,
				ImageResize
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'undo',
					'redo',
					'math',
					'alignment',
					'uploadImage'
					]
				},
				image: {
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageTextAlternative'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			isImageChanged: false,
			isCorrectionImageChanged: false
		};
	},
	mounted () {
		this.folder_path = this.$route.params.folder
		this.loadQuestion()
	},
	methods: {
		async loadQuestion () {
			const postData = {
				folder: this.folder_path,
				question_id: this.questionId,
				subject_id: this.subjectId
			}
			jambFileMgt.fetchSinglePastQuestion(postData)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						for(const item of response.data.question){
							if(parseInt(item.question_id) === parseInt(this.questionId)){
								this.question = item
								this.fform.year_id = this.question.year_id
								this.fform.question_content[0].instruction = this.question.question_instr
								this.fform.question_content[0].id = item.question_id
								this.fform.question_content[0].question = this.$generalHelper.decodeHtmlEntites(this.question.question)
								this.fform.question_content[0].option_a = this.$generalHelper.decodeHtmlEntites(this.question.option_a)
								this.fform.question_content[0].option_b = this.$generalHelper.decodeHtmlEntites(this.question.option_b)
								this.fform.question_content[0].option_c = this.$generalHelper.decodeHtmlEntites(this.question.option_c)
								this.fform.question_content[0].option_d = this.$generalHelper.decodeHtmlEntites(this.question.option_d)
								this.fform.question_content[0].option_e = this.$generalHelper.decodeHtmlEntites(this.question.option_e)
								this.fform.question_content[0].correct_ans = this.$generalHelper.decodeHtmlEntites(this.question.correct_answer)
								//this.fform.question_content[0].correction_type = parseInt(this.question.correction_type)
								this.fform.question_content[0].correction = this.$generalHelper.decodeHtmlEntites(this.question.correction)
								this.fform.question_content[0].correction_image = this.question.correction_image
								this.fform.question_content[0].question_image = this.question.question_image
							}
						}
						this.isLoading = false
						// console.log(this.fform.question_content[0].question.replace(/(\\\(.*?\\\))/g, function($1) {
						// 		return `<span class="math-tex">${$1}</span>`
						// }))
						setTimeout(() => {
							eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
						}, 1000)
					}else{
						this.isLoading = true
						this.showErrorPageAlert = true
						this.errorMessage = response.data.message_data
					}
				})
				.catch((error) => {
					console.log(error.message)
					this.$swal({
						text: 'Error Connecting To Server',
						icon: 'error'
					})
				})
		},
		setImage (image) {
			if(image === null) return
			if(image.length > 0){
				const index = image.indexOf('base64')
				if(index !== -1) {
					return image
				}else{
					return `${jambFileMgt.apiUrl}/load-project-image/JAMB/past_question/${image}`
				}
			}
		},
		async deleteImage (index, image) {
			this.$swal({
				text: `Are you sure you want to delete image`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, update',
				allowOutsideClick: false
			}).then(async (response) => {
				if(response.isConfirmed) {
					if(index === 1){
						if(image === null) return
						if(image.length > 0){
							const index = image.indexOf('base64')
							if(index !== -1) {
								this.fform.question_content[0].question_image = null
								return
							}else{
								await this.deleteImageFromServer(image, 1)
							}
						}
					}else if(index === 2){
						if(image === null) return
						if(image.length > 0){
							const index = image.indexOf('base64')
							if(index !== -1) {
								this.fform.question_content[0].correction_image = null
								return
							}else{
								await this.deleteImageFromServer(image, 2)
							}
						}
					}
				}
			})
		},
		async deleteImageFromServer (imageName, imageIndex) {
			const postData = {
				image_name: imageName,
				subject_id: this.fform.subject_id,
				question_id: this.questionId,
				image_index: imageIndex
			}
			this.loadingMessage = "Deleting Image"
			this.isLoading = true
			jambFileMgt.deletePastQuestionImage(postData)
				.then((response) => {
					if(parseInt(response.data.message_code) === 200){
						this.loadQuestion()
						this.$swal({
							text: response.data.message,
							icon: 'success'
						})
					}else{
						this.isLoading = false
						this.$swal({
							text: response.data.message,
							icon: 'error'
						})
					}
				})
				.catch((error) => {
					this.isLoading = false
					console.log(error.message)
					this.$swal({
						text: 'Error Connecting To Server',
						icon: 'error'
					})
				})
			//console.log(postData)
		},
		async handleFileChange (event) {
			const index = event.target.dataset.id
			const elem = event.target
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.question_content[index].question_image = image
				this.isImageChanged = true
			}
		},
		async handleFileChangeCorrection (event) {
			const index = event.target.dataset.id
			const elem = event.target
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.question_content[index].correction_image = image
				this.isCorrectionImageChanged = true
			}
		},
		computeTotalQuestion () {
			return new Promise((resolve) => {
				const newPastQuestion = []
				this.fform.question_content.map((item) => {
					if(item.question.length > 0 && item.option_a.length > 0 && item.option_b.length && item.option_c.length > 0 && item.option_d.length > 0 && item.correct_ans !== null) {
						newPastQuestion.push(`(${ this.fform.subject_id })`)
					}
				})
				resolve(newPastQuestion.length)
			})
		},
		async onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;

			const totalQuestions = await this.computeTotalQuestion()
			if(totalQuestions === 0){
				this.$swal({
					icon: 'error',
					text: 'Please enter atleast a question before submission'
				})
				return
			}

			this.$swal({
				text: `Are you sure that all input are entered correctly`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, update',
				allowOutsideClick: false
			}).then(async (response) => {
				if(response.isConfirmed) {
					this.isProcessing = true
					const postData = {
						folder: this.folder_path,
						question_content: this.fform,
						question_id: this.questionId,
						old_question_image: this.question?.question_image,
						old_correction_image: this.question?.correction_image
					}
					jambFileMgt.updatePastQuestion(postData)
						.then((response) => {
							this.isProcessing = false
							if(parseInt(response.data.message_code) === 200){
								this.$swal('Past question updated successsfull')
								this.$emit('closeForm')
							}else{
								this.$swal({
									text: response.data.message,
									icon: 'error'
								})
							}
						})
						.catch((error) => {
							this.isProcessing = false
							console.log(error.message)
							this.$swal({
								text: 'Error Connecting To Server',
								icon: 'error'
							})
						})
				}
			})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: #09568d !important;
	height: auto;
	color: #ffffff;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid #f4f5f8;
	background-color: #f4f5f8;
	margin-right: 20px;
}
.frame{
	height: calc(100vh - 320px);
	overflow-y: scroll;
	padding-bottom: 30px;
}
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>
<template>
	<div style="border: 1px solid #09568d;">
		<form @submit.prevent="onSubmit" novalidate>
			<nav class="navbar sticky-top navbar-light bg-dark">
				<div class="container-fluid">
					<span class="navbar-brand mb-0 h6 text-light" style="">{{ vv.question_content.$model.length }} question(s) in total</span>
					<div class="">
						<span class="text-white">Select Entry Count</span>
						<select v-model="initalEntryCount" @change="addMoreRow" style="width: 70px; height: 35px; padding: 5px 5px; margin-right: 20px; margin-left: 10px; border-radius: 5px;">
							<option v-for="i in 20" :key="i">{{ i }}</option>
						</select>

						<!-- <button type="button" class="btn btn-secondary btn-sm" style="margin-right: 20px;" @click="addMoreRow">Add More Question</button> -->
						<button type="submit" class="btn btn-primary btn-sm" :disabled="isProcessing">
							<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-if="isProcessing" style="padding-left: 15px !important;">Saving......</span>
							<span v-if="!isProcessing">Save Question</span>
						</button>
					</div>
				</div>
			</nav>
			<div class="frame bg-white" style="padding: 2rem 1rem;">
				<div class="card">
					<div class="card-body">
						<div class="row g-3">
							<div class="col-6">
								<label class="form-label">Subject name</label>
								<select class="form-select" v-model="vv.subject_id.$model">
									<option :value="initialValue">Select subject</option>
									<option v-for="(item, i) in subjectList" :key="i" :value="item.subject_id">{{ item.subject_title }}</option>
								</select>
								<span class="error">{{ vv?.subject_id?.$errors[0]?.$message }}</span>
							</div>
							<div class="col-6">
								<label class="form-label">Question Year</label>
								<select class="form-select" v-model="vv.year_id.$model">
									<option :value="initialValue">All Years</option>
									<option v-for="(item, i) in yearList" :key="i" :value="item.year_id">{{ item.year_name }}</option>
								</select>
								<span class="error">{{ vv?.year_id?.$errors[0]?.$message }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-2 card" style="margin-top: 20px !important;" v-for="(item, i) in vv.question_content.$model" :key="i">
					<div class="card-header">
						Question {{ i + 1 }}
						<button v-if="i > 0" class="float-end btn btn-danger btn-sm" @click="deleteRow(i)">Delete Row</button>
					</div>
					<div class="card-body">
						<div class="row g-3">
							<div class="col-12 question">
								<label for="">Question Instruction</label>
								<input type="text" class="form-control" v-model="item.instruction">
								<span class="error">{{ item?.instruction?.$errors?.$message }}</span>
							</div>
							<div class="col-12 question">
								<label for="">Question</label>
								<ckeditor :editor="editor" v-model="item.question" :config="editorConfig"></ckeditor>
								<span class="error">{{ item?.question?.$errors?.$message }}</span>
							</div>
							<div class="col-6 options">
								<label for="">Option A</label>
								<ckeditor :editor="editor" v-model="item.option_a" :config="optionConfig"></ckeditor>
								<span class="error">{{ item?.option_a?.$errors?.$message }}</span>
							</div>
							<div class="col-6 options">
								<label for="">Option B</label>
								<ckeditor :editor="editor" v-model="item.option_b" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options">
								<label for="">Option C</label>
								<ckeditor :editor="editor" v-model="item.option_c" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options">
								<label for="">Option D</label>
								<ckeditor :editor="editor" v-model="item.option_d" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6 options">
								<label for="">Option E</label>
								<ckeditor :editor="editor" v-model="item.option_e" :config="optionConfig"></ckeditor>
							</div>
							<div class="col-6">
								<label for="">Correct Answer</label>
								<select class="form-select" v-model="item.correct_ans">
									<option value="null">Select Correct Answer</option>
									<option value="1"> Option A</option>
									<option value="2"> Option B</option>
									<option value="3"> Option C</option>
									<option value="4"> Option D</option>
									<option value="5"> Option E</option>
								</select>
							</div>
							<div class="col-6 options">
								<label for="">Correction </label>
								<ckeditor :editor="editor" v-model="item.correction" :config="correctionConfig"></ckeditor>
							</div>
							<div class="col-6">
								<div>
									<label>Question Image</label>
								</div>
								<img class="image" id="previewImage" :src="setImage(item.question_image)" alt="">
								<label class="custom-file-upload">
									<input type="file" @change="handleFileChange" :data-id="i" id="profile-image-input" accept="image/*"/>
									Add Question Related Image
								</label>
							</div>
							<div class="col-6 options">
								<div>
									<label>Correction Image</label>
								</div>
								<img class="image" id="previewImage" :src="setImage(item.correction_image)" alt="">
								<label class="custom-file-upload">
									<input type="file" @change="handleFileChangeCorrection" :data-id="i" id="profile-image-input" accept="image/*"/>
										Add Correction Image
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Mathematics from 'ckeditor5-math/src/math';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
//import { fileMgt } from '@/services/fileMgt.service'
import { jambFileMgt } from '@/services/jambFileMgt.service'

export default {
	name: 'CreatePastQuestion',
	props: {
		yearList: [Array, Object],
		subjectList: [Array, Object]
	},
	setup() {
		const fform = reactive({
			year_id: null,
			subject_id: null,
			question_content: [
			{
				id: null,
				instruction: '',
				question: '',
				option_a: '',
				option_b: '',
				option_c: '',
				option_d: '',
				option_e: '',
				correct_ans: null,
				question_image: '',
				//correction_type: 0,
				correction: '',
				correction_image: ''
			}
			]
		})
		const rules = computed(() => (
		{
			year_id: { required },
			subject_id: { required },
			question_content: {}
		}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			initalEntryCount: 1,
			folder_path: '',
			topicList: [],
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				UnderlinePlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Mathematics,
				Alignment
				],
				toolbar: {
					items: [
					'bold',
					'underline',
					'italic',
					'undo',
					'redo',
					'math',
					'alignment'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			optionConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				UnderlinePlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Mathematics
				],
				toolbar: {
					items: [
					'bold',
					'underline',
					'italic',
					'undo',
					'redo',
					'math',
					'uploadImage'
					]
				},
				image: {
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageTextAlternative'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 100,
				allowedContent: true
			},
			correctionConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				ItalicPlugin,
				ParagraphPlugin,
				Mathematics,
				Alignment,
				Base64UploadAdapter,
				Image,
				ImageCaption,
				ImageStyle,
				ImageToolbar,
				ImageUpload,
				ImageResize
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'undo',
					'redo',
					'math',
					'alignment',
					'uploadImage'
					]
				},
				image: {
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageTextAlternative'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			initialValue: null,
			isProcessing: false
		}
	},
	mounted () {
		this.folder_path = this.$route.params.folder
	},
	methods: {
		addMoreRow () {
			for(let i = 0; i < this.initalEntryCount; i++){
				this.fform.question_content.push(
					{
						id: null,
						question: '',
						instruction: '',
						option_a: '',
						option_b: '',
						option_c: '',
						option_d: '',
						option_e: '',
						correct_ans: null,
						question_image: '',
						//correction_type: 0,
						correction: '',
						correction_image: ''
					}
				)
			}
			this.initalEntryCount = 0
		},
		deleteRow (i) {
			this.fform.question_content.splice(i, 1)
		},
		setImage (image) {
			return image
		},
		async handleFileChange (event) {
			const index = event.target.dataset.id
			const elem = event.target
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.question_content[index].question_image = image
			}
		},
		async handleFileChangeCorrection (event) {
			const index = event.target.dataset.id
			const elem = event.target
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.question_content[index].correction_image = image
			}
		},
		computeTotalQuestion () {
			return new Promise((resolve) => {
				const newPastQuestion = []
				this.fform.question_content.map((item) => {
					if(item.question.length > 0 && item.option_a.length > 0 && item.option_b.length && item.option_c.length > 0 && item.option_d.length > 0 && item.correct_ans !== null) {
						newPastQuestion.push(`(${ this.fform.subject_id })`)
					}
				})
				resolve(newPastQuestion.length)
			})
		},
		async onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			const totalQuestions = await this.computeTotalQuestion()
			if(totalQuestions === 0){
				this.$swal({
					icon: 'error',
					text: 'Please enter atleast a question before submission'
				})
				return
			}
			this.$swal({
				text: `You have ${totalQuestions} questions to save. Are you sure that all input are entered correctly`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, submit',
				allowOutsideClick: false
			}).then(async (response) => {
				if(response.isConfirmed) {
					this.isProcessing = true
					const postData = {
						question_content: this.fform
					}
					jambFileMgt.storePastQuestion(postData)
						.then((response) => {
							this.isProcessing = false
							if(parseInt(response.data.message_code) === 200){
								this.$swal('Past question saved successsfull')
								this.$emit('closeForm')
							}else{
								this.$swal({
									text: response.data.message,
									icon: 'error'
								})
							}
						})
						.catch((error) => {
							this.isProcessing = false
							console.log(error.message)
							this.$swal({
								text: 'Error Connecting To Server',
								icon: 'error'
							})
						})
				}
			})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: #09568d !important;
	height: auto;
	color: #ffffff;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid #f4f5f8;
	background-color: #f4f5f8;
	margin-right: 20px;
}
.frame{
	height: calc(100vh - 320px);
	overflow-y: scroll;
	padding-bottom: 30px;
}
</style>